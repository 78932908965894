<template>
    <div class="auth-template text-center pt-5">
        <img src="@/assets/images/manteinance.png" alt="" style="max-width:400px" class="mt-5">
        <h1 class="mt-5 text-white">
            Maintenance mode
        </h1>
        <h4 class="text-white">
            We're tightening the nuts on the spaceship. <br> We will be back soon
        </h4>
    </div>
</template>
<style>
    .auth-template{
        background-image: url('/images/auth.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
    }
    .auth-template{
        max-width: 100%;
        overflow-x: hidden;
    }
</style>